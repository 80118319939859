<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入姓名/手机号码" style="width: 220px;" class="filter-item" clearable/>
      <el-select v-model="listQuery.audit_status" class="filter-item" placeholder="审核状态" style="width: 140px" clearable>
        <el-option label="未审核" :value="0"></el-option>
        <el-option label="审核通过" :value="1"></el-option>
        <el-option label="审核失败" :value="2"></el-option>
      </el-select>
      <el-date-picker v-model="listQuery.start_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" placeholder="开始日期" clearable></el-date-picker>
      <el-date-picker v-model="listQuery.end_time" class="filter-item" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" placeholder="截止日期" clearable></el-date-picker>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="dialogVisible = true">添加</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="手机号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>
      <el-table-column label="微信" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.unionid" style="display: flex;flex-direction:column;align-items:center;text-align:center;">
            <img :src="scope.row.avatar" class="user-avatar">
            <span>{{ scope.row.nickname }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="单量" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.order_list_count }}
        </template>
      </el-table-column>
      <el-table-column label="审核状态" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.audit_status == 0">未审核</el-tag>
          <el-tag type="success" v-else-if="scope.row.audit_status == 1">通过</el-tag>
          <template v-else>
            <el-tag type="danger">驳回</el-tag>
            <div>原因:{{scope.row.fail_msg}}</div>
          </template>

        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <div>
            <el-button size="mini" type="primary" @click="handleBind(scope.row)">绑定微信</el-button>
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          </div>
          <div style="margin-top:5px;">
            <el-button size="mini" type="primary" @click="handleRealname(scope.row)">查看实名</el-button>
            <el-button size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>
          </div>
          <div style="margin-top:5px;">
            <el-button size="mini" type="info" @click="handleSettle(scope.row)">结算</el-button>
            <el-button v-if="scope.row.audit_status == 0" size="mini" type="success" @click="handleAudit(scope.row)">审核</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="180px">
        <el-form-item label="姓名">
          <el-input v-model="form.name" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="form.phone" type="number" placeholder="请输入手机号码" />
        </el-form-item>
        <el-form-item label="配送服务-集中时间价格">
          <el-input v-model="form.send_busy_price" type="number" />
        </el-form-item>
        <el-form-item label="配送服务-非集中时间价格">
          <el-input v-model="form.send_normal_price" type="number" />
        </el-form-item>
        <el-form-item label="智慧餐柜-集中时间价格">
          <el-input v-model="form.meal_busy_price" type="number" />
        </el-form-item>
        <el-form-item label="智慧餐柜-非集中时间价格">
          <el-input v-model="form.meal_normal_price" type="number" />
        </el-form-item>
        <el-form-item label="智慧餐柜-第三方投餐价格">
          <el-input v-model="form.out_meal_price" type="number" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisibleWx" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="90px">
        <el-form-item label="扫码绑定">
          <img :src="base64" style="width: 200px; height: 200px;">
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handelCloseWx">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisibleRealname" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="120px">
        <el-form-item label="身份证号码">
          <div>{{sendman.id_card_no}}</div>
        </el-form-item>
        <el-form-item label="身份证正面照">
          <img :src="sendman.id_card_front" style="width: 200px; height: 200px;">
        </el-form-item>
        <el-form-item label="身份证背面照">
          <img :src="sendman.id_card_back" style="width: 200px; height: 200px;">
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleRealname = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisibleAudit" :close-on-click-modal="false" width="40%">
      <el-input type="textarea" placeholder="请输入驳回理由" v-model="fail_msg" maxlength="40" show-word-limit></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="btnLoadingAudit" @click="saveAudit(2)" type="danger">驳回</el-button>
        <el-button :loading="btnLoadingAudit" @click="saveAudit(1)" type="primary">通过</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        audit_status: "",
        start_time: "",
        end_time: ""
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        name: "",
        phone: "",
        send_busy_price: 0,
        send_normal_price: 0,
        meal_busy_price: 0,
        meal_normal_price: 0,
        out_meal_price: 0,
      },
      dialogVisibleWx: false,
      base64: "",
      dialogVisibleRealname: false,
      dialogVisibleAudit: false,
      btnLoadingAudit: false,
      sendman: {},
      fail_msg: ""
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          name: "",
          phone: "",
          send_busy_price: 0,
          send_normal_price: 0,
          meal_busy_price: 0,
          meal_normal_price: 0,
          out_meal_price: 0,
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/sendman/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleEdit(item) {
      this.form = {
        id: item.id,
        school_id: item.school_id,
        name: item.name,
        phone: item.phone,
        send_busy_price: item.send_busy_price,
        send_normal_price: item.send_normal_price,
        meal_busy_price: item.meal_busy_price,
        meal_normal_price: item.meal_normal_price,
        out_meal_price: item.out_meal_price,
      };
      this.dialogVisible = true;
    },
    handleReleaseAll() {
      if (this.ids.length <= 0) {
        this.$message({
          type: "warning",
          message: "请选择项!"
        });
        return;
      }
      this.$confirm("确定要发布选中项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/coupon/release",
          method: "post",
          data: {
            ids: this.ids
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleAudit(item) {
      this.sendman = item;
      this.fail_msg = "";
      this.dialogVisibleAudit = true;
    },
    handelCloseWx() {
      this.dialogVisibleWx = false;
      this.getList();
    },
    saveAudit(audit) {
      this.btnLoadingAudit = true;
      request({
        url: "/api/backend/sendman/audit",
        method: "post",
        data: {
          id: this.sendman.id,
          audit_status: audit,
          fail_msg: this.fail_msg
        }
      })
        .then(() => {
          this.dialogVisibleAudit = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoadingAudit = false;
        });
    },
    handleSettle(item) {
      this.$router.push('/sendman/settle?sendman_id=' + item.id)
    },
    handleDel(item) {
      this.$confirm("确定要删除该派送员吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/sendman/del",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach(element => {
        ids.push(element.id);
      });
      this.ids = ids;
    },
    handleBind(item) {
      this.base64 = "";
      this.dialogVisibleWx = true;
      request({
        url: "/api/common/qrcode/base64",
        method: "post",
        data: {
          url: process.env.VUE_APP_BASE_URL + "/wx/bindSendman?id=" + item.id
        }
      }).then(response => {
        this.base64 = response.data;
      });
    },
    handleRealname(item) {
      this.dialogVisibleRealname = true;
      this.sendman = item;
    },
    saveData() {
      this.form.school_id = this.school_id;
      if (!this.form.school_id) {
        this.$message({
          type: "warning",
          message: "请先选择学校"
        });
        return;
      }
      if (!this.form.name) {
        this.$message({
          type: "warning",
          message: "请输入姓名"
        });
        return;
      }
      if (this.form.phone.length != 11) {
        this.$message({
          type: "warning",
          message: "请输入正确的手机号码"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/sendman/save",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleDownload() {
      request({
        url: "/api/backend/coupon/appCode",
        method: "post",
        data: {
          coupon_id: this.sendCouponId,
          user_id: this.sendToUserId
        }
      }).then(response => {
        let a = document.createElement("a");
        a.href = response.data;
        a.download = "领券中心.png";
        a.click();
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
